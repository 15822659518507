import RequireAuth from 'RequireAuth';
import Layout from 'components/Layout';
import Maintenance from 'components/molecules/Maintenance';
import Config from 'config';
import { LOCAL } from 'constants/clientRoutes';
import Login from 'containers/login';
import CryptoTransaction from 'containers/ops/CryptoTransaction';
import GlobalSingleStockTransaction from 'containers/ops/GlobalSingleStockTransaction';
import GlobalSingleStockTransactionPALNList from 'containers/ops/GlobalSingleStockTransactionPaln';
import GoldSendTransactionList from 'containers/ops/GoldSendTransactions';
import GoldTransaction from 'containers/ops/GoldTransaction';
import AcIndonesianSingleStockTransaction from 'containers/ops/IndonesianSingleStockTransactions';
import MutualFundTransaction from 'containers/ops/MutualFundTransaction';
import OpsAutoDebit from 'containers/ops/autoDebit';
import OpsUserBankAccount from 'containers/ops/bankAccount';
import OpsCryptoTransfer from 'containers/ops/cryptoTransfer';
import KycAutoAssign from 'containers/ops/kyc/view/kycAutoAssign';
import OpsPocketTransactions from 'containers/ops/pocketTransactions';
import OpsUserInsurance from 'containers/ops/userInsurance';
import OpsUsers from 'containers/ops/users';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { checkIfLoggedIn, getCurrentUser, getSfId } from 'services/AuthService';
import { useNotification } from 'services/hooks';
import { useStateValue } from 'utils/redux';
import { MAINTENANCE_MODE, USER_STATE } from 'utils/redux/actions';
import FiBca from './fi/bca';
import CashoutList from './fi/cashout';
import CashoutView from './fi/cashout/view';
import FundTransactionList from './fi/fundTransactions';
import InstallmentList from './fi/installment/list';
import InstallmentView from './fi/installment/view';
import RdnCashoutList from './fi/rdnCashout';
import RdnTopup from './fi/rdnTopUp';
import RefereeList from './fi/referral';
import TopupList from './fi/topUp';
import Forgot from './forgot';
import GoldWithdrawalList from './ops/GoldWithdrawal';
import USDTransaction from './ops/USDTransaction';
import UsIndexTransaction from './ops/USIndexTransaction';
import UserVoucher from './ops/UserVoucher';
import MissionRewardClaim from './ops/mission/rewardClaimList';
import MissionUserAction from './ops/mission/userActionList';
import OptionsTransactions from './ops/optionsTransactions';
import OpsTokopediaUsers from './ops/tokopediaUsers/list';
import OpsUsdDeposit from './ops/usdDeposit';
import Reset from './reset';
import Welcome from './welcome';

const App = () => {
  const [appState, dispatch] = useStateValue();
  const { showNotification } = useNotification();
  useEffect(() => {
    if (checkIfLoggedIn()) {
      getCurrentUser()
        .then((user) => {
          const salesForceId = getSfId();
          dispatch({
            type: USER_STATE,
            user,
            loggedIn: true,
            sfId: salesForceId ? salesForceId : null
          });
        })
        .catch((error) =>
          dispatch({
            type: USER_STATE,
            user: null,
            loggedIn: false,
            sfId: null,
            isMaintenance: (error && error.error_message) === Config.MAINTENANCE_ERROR
          })
        );
    }
    document.addEventListener('error', (e) => {
      if (e.detail.body === Config.MAINTENANCE_ERROR) {
        dispatch({
          type: MAINTENANCE_MODE,
          isMaintenance: true
        });
      }
      showNotification(e.detail.type, e.detail.body, e.detail.title);
    });
    return () => {
      document.removeEventListener('error');
    };
  }, []);

  return appState.isMaintenance ? (
    <Routes>
      <Route path="*" element={<Maintenance />} />
    </Routes>
  ) : (
    <Routes>
      <Route path={LOCAL.LOGIN} element={<Login />} />
      <Route path={LOCAL.FORGOT} element={<Forgot />} />
      <Route path={LOCAL.RESET} element={<Reset />} />
      <Route element={<RequireAuth />}>
        <Route path={LOCAL.WELCOME} element={<Welcome />} />
        <Route element={<Layout />}>
          <Route path={`${LOCAL.OPS}/kyc/agent`} element={<KycAutoAssign />} />
          <Route exact path={`${LOCAL.OPS}/users/*`} element={<OpsUsers />} />
          <Route
            exact
            path={`${LOCAL.OPS}/indonesianSingleStockTransactions`}
            element={<AcIndonesianSingleStockTransaction />}
          />
          <Route exact path={`${LOCAL.OPS}/userInsurance/*`} element={<OpsUserInsurance />} />
          <Route exact path={`${LOCAL.OPS}/autoDebit/*`} element={<OpsAutoDebit />} />
          <Route
            exact
            path={`${LOCAL.OPS}/pocketTransactions/*`}
            element={<OpsPocketTransactions />}
          />
          <Route exact path={`${LOCAL.OPS}/cryptoTransfers/*`} element={<OpsCryptoTransfer />} />
          <Route exact path={`${LOCAL.OPS}/userBankAccounts/*`} element={<OpsUserBankAccount />} />
          <Route
            exact
            path={`${LOCAL.OPS}/mutualFundTransactions`}
            element={<MutualFundTransaction />}
          />
          <Route
            exact
            path={`${LOCAL.OPS}/globalSingleStockTransactions`}
            element={<GlobalSingleStockTransaction />}
          />
          <Route
            exact
            path={`${LOCAL.OPS}/globalSingleStockTransactionPaln`}
            element={<GlobalSingleStockTransactionPALNList />}
          />
          <Route exact path={`${LOCAL.OPS}/cryptoTransactions`} element={<CryptoTransaction />} />
          <Route
            exact
            path={`${LOCAL.OPS}/goldSendTransactions`}
            element={<GoldSendTransactionList />}
          />
          <Route exact path="/fi/installment" element={<InstallmentList />} />
          <Route exact path="/fi/installment/view/:loanId" element={<InstallmentView />} />
          <Route exact path={`${LOCAL.OPS}/withdrawal`} element={<GoldWithdrawalList />} />
          <Route exact path={`${LOCAL.OPS}/missions/userAction`} element={<MissionUserAction />} />
          <Route
            exact
            path={`${LOCAL.OPS}/missions/rewardClaim`}
            element={<MissionRewardClaim />}
          />
          <Route exact path={`${LOCAL.OPS}/goldTransactions`} element={<GoldTransaction />} />
          <Route exact path={`${LOCAL.OPS}/USDTransactions`} element={<USDTransaction />} />
          <Route exact path={`${LOCAL.OPS}/USIndexTransactions`} element={<UsIndexTransaction />} />
          <Route exact path={`${LOCAL.OPS}/fundTransactions`} element={<FundTransactionList />} />
          <Route
            exact
            path={`${LOCAL.OPS}/optionsTransactions/*`}
            element={<OptionsTransactions />}
          />
          <Route exact path={`${LOCAL.OPS}/tokopediaUsers/*`} element={<OpsTokopediaUsers />} />
          <Route exact path={`${LOCAL.FI}/topup`} element={<TopupList />} />
          <Route exact path={`${LOCAL.FI}/bca/*`} element={<FiBca />} />
          <Route exact path={`${LOCAL.FI}/rdnCashout`} element={<RdnCashoutList />} />
          <Route exact path={`${LOCAL.FI}/referral`} element={<RefereeList />} />
          <Route exact path={`${LOCAL.FI}/cashout`} element={<CashoutList />} />
          <Route exact path={`${LOCAL.FI}/cashout/view/:cashoutId`} element={<CashoutView />} />
          <Route exact path={`${LOCAL.FI}/rdnTopUpList`} element={<RdnTopup />} />
          <Route exact path={`${LOCAL.OPS}/usdDeposit/*`} element={<OpsUsdDeposit />} />
          <Route exact path={`${LOCAL.OPS}/userVoucher`} element={<UserVoucher />} />
          <Route path="*" element={<Navigate to={`${LOCAL.OPS}/kyc/agent`} />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
