import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

const tableStyle = {
  [`.MuiTableCell-root`]: { border: '1px solid rgba(224, 224, 224, 1)' },
  '&:nth-of-type(odd)': { backgroundColor: 'rgba(0, 0, 0, 0.05)' }
};

const DisplayData = ({ value, mainKey, currency, keyField, title }) => {
  const titleColumn = !keyField || mainKey === keyField ? 'Total' : value?.name;
  const purchasedUnit = (unit) => {
    switch (title) {
      case 'Crypto Assets':
      case 'Mutual Funds (IDR)':
        return unit?.toFixed(8);
      case 'Gold':
        return unit?.toFixed(6);
      case 'US Equities (GSS)':
      case 'Indonesian Equities (IDSS)':
        return unit?.toFixed(4);
      case 'Forex / USD Wallet':
        return unit;
      default:
        return unit?.toFixed(1);
    }
  };
  const symbol =
    title === 'Gold'
      ? 'gr'
      : title === 'Forex / USD Wallet'
      ? 'USD'
      : value?.symbol || value?.name || '';

  if (title.includes('Mutual Funds') && currency !== value.currency) return null;

  return (
    <TableRow sx={tableStyle}>
      <TableCell>{titleColumn}</TableCell>
      <TableCell align="center">
        {currency === 'IDR' ? Math.floor(value?.valueIdr) : value?.value?.toFixed(2)}
      </TableCell>
      <TableCell align="center">{currency}</TableCell>
      {keyField?.includes('usStocks') && (
        <TableCell align="center">{`Rp ${value?.valueIdr?.toFixed(2)}`}</TableCell>
      )}
      <TableCell align="center">{purchasedUnit(value?.quantity)}</TableCell>
      <TableCell align="center">{symbol}</TableCell>
      <TableCell align="center">{value?.freeCreditIdr}</TableCell>
      <TableCell align="center">{symbol}</TableCell>
      <TableCell align="center">
        {currency === 'IDR' ? value?.weightedCostIdr : value?.weightedCost?.toFixed(2)}
      </TableCell>
      <TableCell align="center">{currency}</TableCell>
      {keyField?.includes('usStocks') && (
        <TableCell align="center">{`Rp ${value?.weightedCostIdr?.toFixed(2)}`}</TableCell>
      )}
      <TableCell align="center">
        {currency === 'IDR' ? value?.unrealisedGainIdr : value?.unrealisedGain?.toFixed(2)}
      </TableCell>
      <TableCell align="center">{currency}</TableCell>
      {keyField?.includes('usStocks') && (
        <TableCell align="center">{`Rp ${value?.unrealisedGainIdr?.toFixed(2)}`}</TableCell>
      )}
      <TableCell align="center">
        {currency === 'IDR'
          ? value?.assetSaleRealisedGainIdr
          : value?.assetSaleRealisedGain?.toFixed(2)}
      </TableCell>
      <TableCell align="center">{currency}</TableCell>
      {keyField?.includes('usStocks') && (
        <TableCell align="center">{`Rp ${value?.assetSaleRealisedGainIdr?.toFixed(2)}`}</TableCell>
      )}
      <TableCell align="center">
        {currency === 'IDR' ? value?.dividendIdr : value?.dividend?.toFixed(2)}
      </TableCell>
      <TableCell align="center">{currency}</TableCell>
      {keyField?.includes('usStocks') && (
        <TableCell align="center">{`Rp ${value?.dividendIdr?.toFixed(2)}`}</TableCell>
      )}
    </TableRow>
  );
};

DisplayData.propTypes = {
  value: PropTypes.object,
  mainKey: PropTypes.string,
  keyField: PropTypes.string,
  currency: PropTypes.string,
  title: PropTypes.string
};

const ProductTable = ({ open, setOpen, asset, keyField, currency = 'IDR', title }) => {
  const fixedHeaders = [
    { field: 'Products' },
    { field: 'Current Balance & Unrealized Profit', col: keyField?.includes('usStocks') ? 13 : 10 },
    { field: 'Realized Profit', col: 6 }
  ];

  const productHeaders = [
    { label: 'Value Own', col: keyField?.includes('usStocks') ? 3 : 2 },
    { label: 'Purchased Unit', col: 2 },
    { label: 'Asset Reward', col: 2 },
    { label: 'Bal Avg Buy Price', col: keyField?.includes('usStocks') ? 3 : 2 },
    { label: 'Unrealized Profit Value', col: keyField?.includes('usStocks') ? 3 : 2 },
    { label: 'Realized from Asset Sales', col: keyField?.includes('usStocks') ? 3 : 2 },
    { label: 'Asset Dividend', col: keyField?.includes('usStocks') ? 3 : 2 }
  ];

  return (
    <Accordion expanded={open} onChange={() => setOpen((prevState) => !prevState)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table sx={{ whiteSpace: 'nowrap' }}>
            <TableBody>
              <TableRow sx={tableStyle}>
                {fixedHeaders.map((row, key) => (
                  <TableCell key={key} align="center" colSpan={row.col || 1}>
                    {row.field}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow sx={tableStyle}>
                <TableCell>{title}</TableCell>
                {productHeaders.length > 0 &&
                  productHeaders.map(({ label, col }) => (
                    <TableCell key={label} align="center" colSpan={col || 1}>
                      {label}
                    </TableCell>
                  ))}
              </TableRow>
              {title === 'Gold' && asset ? (
                <DisplayData currency={currency} keyField={keyField} title={title} value={asset} />
              ) : title === 'Options' && asset ? (
                Object.keys(asset).map((key) => {
                  const option = asset[key];
                  if (Array.isArray(option?.optionPositions)) {
                    return (
                      <Fragment key={key}>
                        {option.optionPositions.length > 0 &&
                          option.optionPositions.map((position) => (
                            <DisplayData
                              key={key}
                              currency={currency}
                              keyField={keyField}
                              title={title}
                              value={position}
                            />
                          ))}
                      </Fragment>
                    );
                  } else {
                    return (
                      <DisplayData
                        key={key}
                        currency={currency}
                        keyField={keyField}
                        mainKey={key}
                        title={title}
                        value={option}
                      />
                    );
                  }
                })
              ) : (
                Object.entries(asset).map(([key, value]) => (
                  <DisplayData
                    key={key}
                    currency={currency}
                    keyField={keyField}
                    mainKey={key}
                    title={title}
                    value={value}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

ProductTable.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  fixedHeaders: PropTypes.array,
  asset: PropTypes.object,
  field: PropTypes.string,
  keyField: PropTypes.string,
  currency: PropTypes.string,
  title: PropTypes.string
};

export default ProductTable;
