import PluangListPage from 'components/organisms/pluangListTable';

const OpsTokopediaUsers = () => {
  const filters = [
    {
      id: 'tokoAccountId',
      type: 'number',
      label: 'Account ID'
    },
    {
      id: 'tokoPhone',
      type: 'text',
      label: 'Phone number'
    },
    {
      id: 'balanceMigrationStatus',
      type: 'dropdown',
      label: 'Status',
      default: '',
      options: [
        { text: 'All', value: '' },
        { text: 'Not Started', value: 'NOT_STARTED' },
        { text: 'Started', value: 'STARTED' },
        { text: 'In Progress', value: 'IN_PROGRESS' },
        { text: 'Completed', value: 'COMPLETED' },
        { text: 'Halted', value: 'HALTED' }
      ]
    }
  ];

  const headers = [
    { header_name: 'Account Id', db_column_name: 'tokoAccountId' },
    { header_name: 'Phone Number', db_column_name: 'tokoPhone' },
    { header_name: 'Account Created At', db_column_name: 'accountCreatedAt' },
    { header_name: 'Balance Migration Status', db_column_name: 'balanceMigrationStatus' },
    { header_name: 'Balance Migrated At', db_column_name: 'balanceMigratedAt' },
    { header_name: 'From User Id', db_column_name: 'tokoUserId' },
    { header_name: 'To User Id', db_column_name: 'pluangUserId' }
  ];

  return (
    <PluangListPage
      filters={filters}
      headers={headers}
      resource="TOKOPEDIA_USER"
      title="Tokopedia Users"
    />
  );
};

export default OpsTokopediaUsers;
